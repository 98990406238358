<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>myPIMS</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="물품 예약"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <div class="mb-20">
                                <span class="float-right">＊필수 입력 항목입니다.</span>
                                <div class="clear"></div>
                            </div>
                            <table class="table-row">
                                <colgroup>
                                    <col width="180">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><span class="txt-red">*</span> 대여기간</th>
                                        <td>
                                            <v-datepicker v-model="mod.sdate" @dayclick="mod.changeDate()" class="datepicker-group mr-10">
                                                <template #default="{ inputValue, togglePopover }">
                                                    <div>
                                                        <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                        <button class="btn-cal" @click="togglePopover()"></button>
                                                    </div>
                                                </template>
                                            </v-datepicker>
                                            ~
                                            <v-datepicker v-model="mod.edate" @dayclick="mod.changeDate()" class="datepicker-group ml-10">
                                                <template #default="{ inputValue, togglePopover }">
                                                    <div>
                                                        <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                        <button class="btn-cal" @click="togglePopover()"></button>
                                                    </div>
                                                </template>
                                            </v-datepicker>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 대여물품</th>
                                        <td>
                                            <select name="cat1" id="cat1" class="w-200px mr-10" v-model="mod.cat1" @change="mod.setCat1">
                                                <option value="">중분류 선택</option>
                                                <option :value="irow" v-for="(irow, i) in mod.categories" :key="i">{{ irow.name }}</option>
                                            </select>
                                            <select name="cat2" id="cat2" class="w-200px" v-model="mod.cat2" @change="mod.doSearchProd">
                                                <option value="">소분류 선택</option>
                                                <option :value="v" v-for="(v, k) in mod.cat1.children" :key="k">{{ v }}</option>
                                            </select>
                                            <select name="prd" id="prd" class="w-200px ml-10" v-model="mod.prd">
                                                <option value="">물품 선택</option>
                                                <option :value="v" v-for="(v, k) in mod.products" :key="k">{{ v.title }}</option>
                                                <option value="" v-if="mod.products.length==0">선택 가능한 물품이 없습니다.</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 사용목적</th>
                                        <td>
                                            <input type="text" class="w-100per" v-model="mod.use_memo">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 사용위치</th>
                                        <td>
                                            <input type="text" class="w-100per" v-model="mod.use_loc">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비고</th>
                                        <td>
                                            <textarea class="w-100per h-100px" v-model="mod.etc_memo"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-left mt-30" @click="mod.goList">목록</button>
                            <button class="btn-default float-left mt-30 ml-10" @click="mod.doDelete">삭제</button>
                            <button class="btn-default float-right mt-30" @click="mod.doSubmit">수정</button>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DatePicker } from 'v-calendar'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    components: {
        'v-datepicker' : DatePicker 
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast();

        const mod = reactive({
            idx   : 0,
            title : '',

            categories : [],
            products   : [],

            sdate : new Date(), edate : new Date(),
            cat1 : '', cat2 : '', prd : '',
            use_memo : '',
            use_loc  : '',
            etc_memo : '',

            changeDate : () => {
                if( mod.sdate && mod.edate && mod.cat1.name && mod.cat2 ){
                    mod.doSearchProd();
                }
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        mod.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            setCat1 : () => {
                mod.cat2 = "";
                mod.products = [];
                mod.prd = "";
            },

            doSearchProd : () => {
                let params = {
                    sdate : mod.sdate,
                    edate : mod.edate,
                    cat1  : mod.cat1.name,
                    cat2  : mod.cat2,
                    idx   : mod.idx
                };

                if( mod.edate < mod.sdate ){
                    toast.error("대여기간을 정확히 입력하세요.");
                    mod.products = [];
                    mod.prd = "";
                    return;
                }

                axios.get("/rest/mypims/getRentalProd", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.products = res.data.list;

                        let is_set = false;
                        if( mod.title != '' ){
                            for(let i=0; i<mod.products.length; i++){
                                let irow = mod.products[i];

                                if( irow.title == mod.title ){
                                    mod.prd = irow;
                                    is_set = true;
                                    break;
                                }
                            }
                        }

                        if( is_set == false ) mod.prd = "";

                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/mypims/getRentalInfo", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        mod.sdate = res.data.sdate;
                        mod.edate = res.data.edate;

                        mod.use_memo = res.data.use_memo;
                        mod.use_loc  = res.data.use_loc;
                        mod.etc_memo = res.data.etc_memo;

                        for(let i=0; i<mod.categories.length; i++){
                            let irow = mod.categories[i];
                            if( res.data.cat1 == irow.name ){
                                mod.cat1 = irow;

                                for(let j=0; j<irow.children.length; j++){
                                    if( res.data.cat2 == irow.children[j] ){
                                        mod.cat2 = irow.children[j];
                                        mod.doSearchProd();
                                        break;
                                    }
                                }
                                break;
                            }
                        }

                        mod.title = res.data.title;

                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx   : mod.idx,

                    sdate : mod.sdate,
                    edate : mod.edate,
                    cat2  : mod.cat2,

                    use_memo : mod.use_memo,
                    use_loc  : mod.use_loc,
                    etc_memo : mod.etc_memo
                };

                if( !params.sdate ){
                    alert("대여기간을 정확히 입력하세요.");
                    return;
                }

                if( !params.edate ){
                    alert("대여기간을 정확히 입력하세요.");
                    return;
                }

                if( typeof mod.cat1 == 'undefined' || mod.cat1 == '' || mod.cat1.name == '' ){
                    // toast.show("asdf");
                    alert("중분류를 선택하세요.");
                    return;
                } else {
                    params.cat1 = mod.cat1.name;
                }

                if( params.cat2 == "" ){
                    alert("소분류를 선택하세요.");
                    return;
                }

                if( typeof mod.prd == 'undefined' || mod.prd == '' || mod.prd.idx == '' ){
                    alert("대여 물품을 선택하세요.");
                    return;
                }
                params.prd = mod.prd.idx;

                if( params.use_memo == "" ){
                    alert("사용목적을 입력하세요.");
                    return;
                }

                if( params.use_loc == "" ){
                    alert("사용위치를 입력하세요.");
                    return;
                }
                
                axios.post("/rest/mypims/modRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        alert("수정되었습니다.");
                        router.go(-1);
                    } else {
                        console.info(res);
                    }
                });
            },

            doDelete : () => {
                if( confirm("삭제하시겠습니까?") == true ){
                    let params = {
                        idx : mod.idx
                    };
                    axios.post("/rest/mypims/delRental", params).then((res) => {
                        if( res.data.err == 0 ){
                            router.go(-1);
                        } else {
                            console.info(res);
                        }
                    });
                }
            },

            goList : () => {
                if( confirm("수정한 내용이 저장되지 않습니다. 목록으로 이동하시겠습니까?") == true ){
                    router.go(-1);
                }
            }
        });

        onMounted(() => {
            mod.getCategory();

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.doSearch();
        });

        onUnmounted(() => {

        });

        return { mod };
    }
}
</script>

<style lang="scss" scoped>
</style>